import { useContext } from "react";

/* Import Global Contexts */
import CartContext from "@context/cart";

const useCart = () => {
  const context = useContext(CartContext);

  if (context === undefined) {
    throw new Error("useCart must be used within CartContext");
  }

  return context;
};

export default useCart;
