import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import { Link } from "gatsby";
import { Link as ScrollLink } from "react-scroll";

// Import Global Hooks
import useNavigation from "@hooks/useNavigation";
import useCart from "@hooks/useCart";

/* Import Local Style(s) */
import "./mobile-header.scss";

/* Import Local Asset(s) */
import LogoSvgSrc from "../../assets/no-tricks-logo.svg";
import CloseIconSvgSrc from "./assets/close-icon.svg";
import HamburgerClosedIconSvgSrc from "./assets/hamburger-closed.svg";

const MobileHeader = ({ location }) => {
  const [headerOffsetHeight, setHeaderOffsetHeight] = useState(86);
  const headerElementReference = useRef();
  const [isNavigationOpen, setIsNavigationOpen] = useNavigation();
  const { cart, isOpen, setIsOpen } = useCart();

  useEffect(() => {
    if (headerElementReference && headerElementReference.current) {
      setHeaderOffsetHeight(headerElementReference.current.offsetHeight * -1);
    }
  }, [headerElementReference]);

  const closeNavigation = () => {
    setIsNavigationOpen(false);
  };

  const closeCart = () => {
    setIsOpen(false);
  };

  return (
    <>
      <header
        className="header mobile-header"
        id="header"
        ref={headerElementReference}
      >
        <button
          onClick={() => {
            // open cart
            setIsOpen(!isOpen);
            // close navigation
            closeNavigation();
          }}
        >
          Cart ({cart.length})
        </button>
        <Link to="/">
          <img src={LogoSvgSrc} alt="No Tricks Logo" />
        </Link>
        <button
          className="hamburger"
          style={{ minimumWidth: "31px" }}
          onClick={() => {
            setIsNavigationOpen(!isNavigationOpen);
            closeCart();
          }}
        >
          <img src={HamburgerClosedIconSvgSrc} alt="Navigation Closed Icon" />
        </button>
        <nav
          className={classnames("navigation", {
            "navigation-open": isNavigationOpen,
          })}
        >
          {/* Close BUTTON Button */}
          <button onClick={closeNavigation} className="close">
            <img src={CloseIconSvgSrc} alt="Close" />
          </button>
          <ul>
            <li>
              {location.pathname === "/" ? (
                <>
                  <ScrollLink
                    to="info"
                    smooth="true"
                    offset={headerOffsetHeight}
                    onClick={closeNavigation}
                  >
                    Info
                  </ScrollLink>
                  <ScrollLink
                    to="shop"
                    smooth="true"
                    offset={headerOffsetHeight}
                    onClick={closeNavigation}
                  >
                    Shop
                  </ScrollLink>
                  <ScrollLink
                    to="links"
                    smooth="true"
                    offset={headerOffsetHeight}
                    onClick={closeNavigation}
                  >
                    Links
                  </ScrollLink>
                  <ScrollLink
                    to="research"
                    smooth="true"
                    offset={headerOffsetHeight}
                    onClick={closeNavigation}
                  >
                    Research
                  </ScrollLink>
                  <a
                    href="https://www.instagram.com/notricksnotricksnotricks/?hl=en"
                    target="_blank"
                    onClick={closeNavigation}
                  >
                    Follow
                  </a>
                </>
              ) : (
                <>
                  <Link
                    to="/#info"
                    smooth="true"
                    offset={headerOffsetHeight}
                    onClick={closeNavigation}
                  >
                    Info
                  </Link>
                  <Link
                    to="/#shop"
                    smooth="true"
                    offset={headerOffsetHeight}
                    onClick={closeNavigation}
                  >
                    Shop
                  </Link>
                  <Link
                    to="/#links"
                    smooth="true"
                    offset={headerOffsetHeight}
                    onClick={closeNavigation}
                  >
                    Links
                  </Link>
                  <Link
                    to="/#research"
                    smooth="true"
                    offset={headerOffsetHeight}
                    onClick={closeNavigation}
                  >
                    Research
                  </Link>
                  <a
                    href="https://www.instagram.com/notricksnotricksnotricks/?hl=en"
                    target="_blank"
                    onClick={closeNavigation}
                  >
                    Follow
                  </a>
                </>
              )}
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default MobileHeader;
