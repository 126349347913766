import React, { useEffect } from "react";

/* Import Global Components */
import Loader from "@components/loader/loader";
import Gdpr from "@components/gdpr/gdpr";
import Header from "@components/header/header";
import Cart from "@components/cart/cart";
import Main from "@components/main/main";
import Footer from "@components/footer/footer";

/* Import Global Style(s) */
import "@styles/app.scss";

const Layout = ({ children, uri, location }) => {
  const stopResponsiveTransition = () => {
    const classes = document.body.classList;
    let timer = null;
    window.addEventListener("resize", function () {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      } else {
        classes.add("stop-transition");
      }
      timer = setTimeout(() => {
        classes.remove("stop-transition");
        timer = null;
      }, 100);
    });
  };

  useEffect(() => {
    stopResponsiveTransition();
  }, []);
  return (
    <>
      <Loader />
      <Gdpr />
      <Header location={location} />
      <Cart />
      <Main uri={uri}>{children}</Main>
      <Footer />
    </>
  );
};

export default Layout;
