import React from "react";
import slug from "slug";
import classnames from "classnames";

/* Import Global Hooks */
import useGdpr from "@hooks/useGdpr";

/* Import Local Styles */
import { main, gdprAccepted } from "./main.module.scss";

const Main = ({ uri, children }) => {
  const [isGdprAccepted] = useGdpr();

  return (
    <main
      className={classnames(
        main,
        slug(uri.substring(1), {
          charmap: {
            "/": "-",
          },
        })
      )}
    >
      {children}
    </main>
  );
};

export default Main;
