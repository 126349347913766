import { useStaticQuery, graphql } from "gatsby";

const contactConfigurationQuery = graphql`
  query ContactQuery {
    sanityContact(_id: { eq: "contact" }) {
      zipCode
      unitNumber
      street
      state
      phoneNumber
      emailAddress
      city
      buildingNumber
    }
  }
`;

export const useContact = () => {
  const { sanityContact: contact } = useStaticQuery(contactConfigurationQuery);

  return contact;
};

export default useContact;
