import { useContext } from "react";

/* Import Global Contexts */
import NavigationContext from "@context/navigation";

const useNavigation = () => {
  const context = useContext(NavigationContext);

  if (context === undefined) {
    throw new Error("useNavigation must be used within NavigationContext");
  }

  return context;
};

export default useNavigation;
