import React, { useState, useEffect } from "react";
import ls from "local-storage";

import GdprContext from "@context/gdpr";

const GdprProvider = ({ children }) => {
  const [isGdprAccepted, setIsGdprAccepted] = useState();

  useEffect(() => {
    const gdprAcceptedLocalStorageValue = ls("no-tricks-gdpr");
    if (
      // if falsy
      !gdprAcceptedLocalStorageValue ||
      // if undefined
      gdprAcceptedLocalStorageValue === "undefined" ||
      // is a string other than true
      ((typeof gdprAcceptedLocalStorageValue === "string" ||
        gdprAcceptedLocalStorageValue instanceof String) &&
        gdprAcceptedLocalStorageValue !== "true")
    ) {
      setIsGdprAccepted(false);
    } else {
      setIsGdprAccepted(true);
    }
  }, []);

  useEffect(() => {
    ls("no-tricks-gdpr", isGdprAccepted);
  }, [isGdprAccepted]);

  return (
    <GdprContext.Provider value={[isGdprAccepted, setIsGdprAccepted]}>
      {children}
    </GdprContext.Provider>
  );
};

export default GdprProvider;
