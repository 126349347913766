import React from "react";

/* Import Global Provider(s) */
import NavigationProvider from "./src/providers/navigation";
import CartProvider from "./src/providers/cart";
import GdprProvider from "./src/providers/gdpr";

export const wrapRootElement = ({ element }) => (
  <NavigationProvider>
    <GdprProvider>
      <CartProvider>{element} </CartProvider>
    </GdprProvider>
  </NavigationProvider>
);
