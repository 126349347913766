import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import { Link as ScrollLink } from "react-scroll";
import { StaticImage } from "gatsby-plugin-image";

// Import Global Hooks
import useCart from "@hooks/useCart";
import useContact from "@hooks/useContact";

/* Import Local Style(s) */
import "./desktop-header.scss";

/* Import Local Asset(s) */
import LogoSvgSrc from "../../assets/no-tricks-logo.svg";

const DesktopHeader = ({ location }) => {
  const {
    streetAddress,
    zipCode,
    unitNumber,
    street,
    state,
    phoneNumber,
    emailAddress,
    city,
    buildingNumber,
  } = useContact();
  const [headerOffsetHeight, setHeaderOffsetHeight] = useState(86);
  const headerElementReference = useRef();
  const { cart, isOpen, setIsOpen } = useCart();

  useEffect(() => {
    if (headerElementReference && headerElementReference.current) {
      setHeaderOffsetHeight(headerElementReference.current.offsetHeight * -1);
    }
  }, [headerElementReference]);

  return (
    <header className="header" id="header" ref={headerElementReference}>
      <Link to="/">
        <img src={LogoSvgSrc} alt="No Tricks Logo" />
      </Link>
      <div>
        <p>No tricks is a N.Y. based audio/visual company</p>
        <p>
          {streetAddress} {buildingNumber} {city}, {state} {zipCode}{" "}
          {`#${unitNumber}`}
        </p>
        <p>
          For service call <a href="tel:+19293973898">+19293973898</a> | E:{" "}
          <a href="mailto:info@notricks.co">info@notricks.co</a>
        </p>
        <nav>
          <ul>
            <li>
              {location.pathname === "/" ? (
                <>
                  <ScrollLink
                    to="info"
                    smooth="true"
                    offset={headerOffsetHeight}
                  >
                    Info,
                  </ScrollLink>
                  <ScrollLink
                    to="shop"
                    smooth="true"
                    offset={headerOffsetHeight}
                  >
                    Shop,
                  </ScrollLink>
                  <ScrollLink
                    to="links"
                    smooth="true"
                    offset={headerOffsetHeight}
                  >
                    Links,
                  </ScrollLink>
                  <ScrollLink
                    to="research"
                    smooth="true"
                    offset={headerOffsetHeight}
                  >
                    Research
                  </ScrollLink>
                </>
              ) : (
                <>
                  <Link to="/#info" smooth="true" offset={headerOffsetHeight}>
                    Info,
                  </Link>
                  <Link to="/#shop" smooth="true" offset={headerOffsetHeight}>
                    Shop,
                  </Link>
                  <Link to="/#links" smooth="true" offset={headerOffsetHeight}>
                    Links,
                  </Link>
                  <Link
                    to="/#research"
                    smooth="true"
                    offset={headerOffsetHeight}
                  >
                    Research
                  </Link>
                </>
              )}
            </li>
          </ul>
        </nav>
      </div>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        Cart ({cart.length})
      </button>
    </header>
  );
};

export default DesktopHeader;
