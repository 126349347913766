import React from "react";
import classnames from "classnames";

/* Import Global Hooks */
import useCart from "@hooks/useCart";

/* Import Local Styles */
import * as styles from "./variant.module.scss";

/* Import Local Assets */
// import TestItemPngSrc from "./assets/test-item.png";

const Variant = ({ variant }) => {
  const { loading, removeLineItem, updateVariantQuantity } = useCart();
  if (!variant.attrs) {
    return null;
  }

  const quantity = variant.attrs.quantity.value;

  const handleReduceQuantity = (event) => {
    if (quantity <= 1) {
      removeLineItem(variant.id);
    } else {
      updateVariantQuantity(variant.id, quantity - 1);
    }
  };

  const handleIncreaseQuantity = (event) => {
    updateVariantQuantity(variant.id, quantity + 1);
  };

  return (
    <li className={classnames("variant", styles.variant)}>
      <img
        src={variant.variant.image.src}
        alt="Product"
        style={{ width: "90px" }}
      />
      <div
        className={classnames("quantity", styles.quantity, {
          [styles.loading]: loading,
        })}
      >
        <button onClick={handleReduceQuantity} disabled={loading}>
          -
        </button>
        <p>{quantity}</p>
        <button onClick={handleIncreaseQuantity} disabled={loading}>
          +
        </button>
      </div>
      <p className="title">
        {variant.title}
        <br />
        {variant.variant.title}
      </p>
      <p className="price">${variant.variant.price}</p>
    </li>
  );
};

export default Variant;
