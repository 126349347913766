import { createContext } from "react";
import Client from "shopify-buy";

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_MYSHOPIFY_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
);

const defaultValues = {
  cart: [],
  loading: false,
  isOpen: false,
  addVariantToCart: () => {},
  updateVariantQuantity: () => {},
  removeLineItem: () => {},
  setIsOpen: () => {},
  client,
  checkout: {
    id: "",
    lineItems: [],
    webUrl: "",
  },
};
const CartContext = createContext(defaultValues.cart);

export default CartContext;
