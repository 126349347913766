import React from "react";
import { useWindowSize } from "react-use";

/* Import Local Component(s) */
import DesktopHeader from "./components/desktopHeader/desktopHeader";
import MobileHeader from "./components/mobileHeader/mobileHeader";

const Header = ({ location }) => {
  const { width } = useWindowSize();

  if (width > 768) {
    return <DesktopHeader location={location} />;
  } else {
    return <MobileHeader location={location} />;
  }
};

export default Header;
