import React from "react";
import { Link } from "gatsby";
import classnames from "classnames";
import { useWindowSize } from "react-use";
import { BsInstagram } from "react-icons/bs";

/* Import Global Hooks */
import useGdpr from "@hooks/useGdpr";
import useContact from "@hooks/useContact";

/* Import Local Styles */
import * as styles from "./footer.module.scss";

/* Import Local Assets */
import NoTricksLogoFooterSvgSrc from "./assets/no-tricks-logo-footer.svg";
import NoTricksLogoFooterMobileSvgSrc from "./assets/no-tricks-mobile-footer.svg";

const Footer = () => {
  const [gdprAccepted] = useGdpr();
  const {
    streetAddress,
    zipCode,
    unitNumber,
    street,
    state,
    phoneNumber,
    emailAddress,
    city,
    buildingNumber,
  } = useContact();
  const { width } = useWindowSize();

  return width > 991 ? (
    <footer className={classnames("footer", styles.footer, {})}>
      <div className={classnames("address", styles.address)}>
        <p>General Offices:</p>
        <br />
        <p>{street}</p>
        {buildingNumber ? (
          <p>
            {buildingNumber} {city}
          </p>
        ) : (
          <p>{city}</p>
        )}
        {unitNumber ? (
          <p>
            {state} {zipCode} {`#${unitNumber}`}
          </p>
        ) : (
          <p>
            {state} {zipCode}
          </p>
        )}
      </div>
      <div className={classnames("contact", styles.contact)}>
        <p>For Service call:</p>
        <a href={`tel:+${phoneNumber}`}>+{phoneNumber}</a>
        <br />
        <p>General Inquiry:</p>
        <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
      </div>
      <div className={classnames("customer-service", styles.customerService)}>
        <p>Customer Service:</p>
        <br />
        <Link to="/policies/shipping-policy">Shipping</Link>
        <Link to="/policies/refund-policy">Returns</Link>
        <Link to="/policies/terms-of-service">Terms</Link>
      </div>
      <p className={classnames("copyright", styles.copyright)}>
        All Rights Reserved. No Tricks Research & Recordings Inc. ©
        {new Date().getFullYear()}
      </p>
      <div className={classnames("social", styles.social)}>
        <a
          href="https://www.instagram.com/notricksnotricksnotricks"
          target="_blank"
        >
          <BsInstagram />
        </a>
      </div>
      <img
        src={NoTricksLogoFooterSvgSrc}
        alt="No Tricks Logo"
        className={classnames("logo", styles.logo)}
      />
    </footer>
  ) : (
    <footer className={classnames("footer", styles.footer, {})}>
      <img src={NoTricksLogoFooterMobileSvgSrc} />
    </footer>
  );
};

export default Footer;
