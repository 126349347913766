import React, { useState, useEffect } from "react";
import classnames from "classnames";

/* Import Global Hooks */
import useGdpr from "@hooks/useGdpr";

/* Import Local Style(s) */
import * as styles from "./gdpr.module.scss";

/* Import Local Asset(s) */
import NoTricksResearchAndRecordingsLogoPngSrc from "./assets/no-tricks-research-and-recordings-logo.png";

const Gdpr = () => {
  const [gdprAccepted, setGdprAccepted] = useGdpr();
  const [gdprVisible, setGdprVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setGdprVisible(true);
    }, 400);
  }, []);

  return (
    <div
      className={classnames("gdpr", styles.gdpr, {
        [styles.gdprAccepted]: gdprAccepted,
        [styles.gdprVisible]: gdprVisible && !gdprAccepted,
      })}
    >
      <div className={classnames("policy", styles.policy)}>
        <p>
          This site logs your access and uses only cookies that are required
          within our valid interest.
        </p>
        <p>
          For more info and your rights regarding data privacy, see privacy
          policy
        </p>
      </div>
      <button
        className={classnames("accept", styles.accept)}
        onClick={() => {
          setGdprAccepted(true);
        }}
      >
        Acknowledge
      </button>
      {/* <p>test</p> */}
      <img
        className={classnames("logo", styles.logo)}
        src={NoTricksResearchAndRecordingsLogoPngSrc}
        alt="No Tricks Logo"
      />
    </div>
  );
};

export default Gdpr;
