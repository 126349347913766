import React, { useState, useEffect } from "react";
import classnames from "classnames";

/* Import Local Style(s) */
import * as styles from "./loader.module.scss";

const Loader = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 100);
  }, []);

  return isLoaded ? null : (
    <div
      className={classnames("loader", styles.loader, {
        [styles.isLoaded]: isLoaded,
      })}
    />
  );
};

export default Loader;
