import React from "react";
import { Link } from "gatsby";
import classnames from "classnames";

/* Import Global Hooks */
import useCart from "@hooks/useCart";

/* Import Local Components */
import Variant from "./components/variant/variant";

/* Import Local Styles */
import * as styles from "./cart.module.scss";

/* Import Local Assets*/
import CloseIconSvgSrc from "./assets/close-icon.svg";

import "./cart.scss";

const Cart = () => {
  const { cart, checkout, isOpen, setIsOpen } = useCart();

  const closeCart = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={classnames("cart", styles.cart, {
        [styles.isOpen]: isOpen,
      })}
    >
      {/* Close Cart Button */}
      <button
        onClick={() => {
          setIsOpen(false);
        }}
        className={classnames("close-cart", styles.closeCart)}
      >
        <img src={CloseIconSvgSrc} alt="Close" />
      </button>
      {/* Variants */}
      <ul>
        {cart.map((variant) => {
          return <Variant key={variant.id} variant={variant} />;
        })}
      </ul>
      {/* Subtotal */}
      <div className={classnames("subtotal", styles.subtotal)}>
        <p>Subtotal</p>
        <p>{checkout.subtotalPrice ? `$${checkout.subtotalPrice}` : "$0.00"}</p>
      </div>
      {/* Checkout Link */}
      <div className={classnames("checkout", styles.checkout)}>
        <p>Shipping and Tax calculated at Checkout</p>
        <button
          onClick={() => window.open(checkout.webUrl)}
          disabled={cart.length === 0}
        >
          Checkout
        </button>
      </div>
      {/* Customer Service Links */}
      <div className={classnames("links", styles.links)}>
        <p>Customer Service:</p>
        <br />
        <ul>
          <li>
            <Link to="/policies/shipping-policy" onClick={closeCart}>
              Shipping
            </Link>
            <Link to="/policies/refund-policy" onClick={closeCart}>
              Returns
            </Link>
            <Link to="/policies/terms-of-service" onClick={closeCart}>
              Terms
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Cart;
