import React, { useState } from "react";

import NavigationContext from "@context/navigation";

const NavigationProvider = ({ children }) => {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);

  return (
    <NavigationContext.Provider value={[isNavigationOpen, setIsNavigationOpen]}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
