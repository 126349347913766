// primarily based on: https://designcode.io/react-hooks-handbook-gatsby-shopify-3
import React, { useState, useEffect } from "react";
import Client from "shopify-buy";

import CartContext from "@context/cart";

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_MYSHOPIFY_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
);

const defaultValues = {
  cart: [],
  loading: false,
  isOpen: false,
  addVariantToCart: () => {},
  updateVariantQuantity: () => {},
  removeLineItem: () => {},
  setIsOpen: () => {},
  client,
  checkout: {
    id: "",
    lineItems: [],
    webUrl: "",
  },
};

const CartProvider = ({ children }) => {
  const isBrowser = typeof window !== `undefined`;
  const localStorageKey = `shopify_checkout_id`;
  const [cart, setCart] = useState(defaultValues.cart);
  const [checkout, setCheckout] = useState(defaultValues.checkout);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Retrieve Existing Checkout or Create New Checkout
  useEffect(() => {
    const setCheckoutItem = (checkout) => {
      if (isBrowser) {
        localStorage.setItem(localStorageKey, checkout.id);
      }
      setCart(checkout.lineItems);
      setCheckout(checkout);
    };

    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          );
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout);
            return;
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null);
        }
      }

      const newCheckout = await client.checkout.create();
      setCheckoutItem(newCheckout);
    };

    initializeCheckout();
  }, [localStorageKey, isBrowser]);

  const addVariantToCart = async (product, variantId, quantity) => {
    setLoading(true);

    if (checkout.id === "") {
      console.error("No checkout ID assigned.");
      return;
    }

    const checkoutID = checkout.id;
    const parsedQuantity = parseInt(quantity, 10);

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parsedQuantity,
      },
    ];

    try {
      const res = await client.checkout.addLineItems(
        checkoutID,
        lineItemsToUpdate
      );
      setCheckout(res);
      setCart(res.lineItems);
      setLoading(false);
      if (!isOpen) {
        setIsOpen(true);
      }
    } catch (error) {
      setLoading(false);
      console.error(`Error in addVariantToCart: ${error}`);
    }
  };

  const updateVariantQuantity = async (variantId, quantity) => {
    setLoading(true);

    if (checkout.id === "") {
      console.error("No checkout ID assigned.");
      return;
    }

    const checkoutID = checkout.id;
    const parsedQuantity = parseInt(quantity, 10);

    const lineItemsToUpdate = [
      {
        id: variantId,
        quantity: parsedQuantity,
      },
    ];

    try {
      const res = await client.checkout.updateLineItems(
        checkoutID,
        lineItemsToUpdate
      );
      setCheckout(res);
      setCart(res.lineItems);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(`Error in addVariantToCart: ${error}`);
    }
  };

  const removeLineItem = async (variantId) => {
    setLoading(true);
    try {
      const res = await client.checkout.removeLineItems(checkout.id, [
        variantId,
      ]);
      setCheckout(res);

      setCart(res.lineItems);
      setLoading(false);
      if (res.lineItems.length === 0) {
        setIsOpen(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(`Error in removeLineItem: ${error}`);
    }
  };

  return (
    <CartContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        updateVariantQuantity,
        removeLineItem,
        setIsOpen,
        cart,
        checkout,
        loading,
        isOpen,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
