import { useContext } from "react";

/* Import Global Contexts */
import GdprContext from "@context/gdpr";

const useGdpr = () => {
  const context = useContext(GdprContext);

  if (context === undefined) {
    throw new Error("useGdpr must be used within GdprContext");
  }

  return context;
};

export default useGdpr;
